.input_data_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.output_data_wrap {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10%;
}
